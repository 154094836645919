import { useState, useEffect, useCallback } from 'react';
import ReactMapGL, { Source, Layer, NavigationControl } from 'react-map-gl';
import ControlPanel from './ControlPanel';
import { dataLayer } from './map-style.js';
import Loader from "react-loader-spinner";
import axios from 'axios';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const districts = require("./paraguay_2019_distritos.json")

function Map() {
    const [viewport, setViewport] = useState({
        width: 400,
        height: 400,
        latitude: -23.4380203,
        longitude: -58.4483065,
        zoom: 6,
    });
    const [year, setYear] = useState("2021");
    const [all_districts, setDistritos] = useState([]);
    const [distrito, setDistrito] = useState();
    const [hoverInfo, setHoverInfo] = useState(null);
    useEffect(() => {
        GetElectionsAll()
    }, []);

    useEffect(() => {
        switch (year) {
            case "1996":
                ChangeElections(all_districts[0])
                break
            case "2001":
                ChangeElections(all_districts[1])
                break
            case "2006":
                ChangeElections(all_districts[2])
                break
            case "2010":
                ChangeElections(all_districts[3])
                break
            case "2015":
                ChangeElections(all_districts[4])
                break
            case "2021":
                ChangeElections(all_districts[5])
                break
            default:
                break
        }
    }, [year])

    const GetElectionsAll = async () => {
        await GetElections(1996, false)
        await GetElections(2001, false)
        await GetElections(2006, false)
        await GetElections(2010, false)
        await GetElections(2015, false)
        await GetElections(2021, true)
    }

    const GetElections = async (actual_year, last) => {
        const response = await axios.get(process.env.REACT_APP_BASE_URL + "/elected_candidates/?cargo=INTENDENTE&year=" + actual_year.toString())
        const data = [...response.data]
        setDistritos(oldArray => [...oldArray, data])
        if (last) {
            const distritos = districts
            let change = false
            for (var i = 0; i < distritos.features.length; i++) {
                for (var j = 0; j < data.length; j++) {
                    if (data[j].district.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\W/g, '') === distritos.features[i].properties.dist_desc_.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\W/g, '')) {
                        distritos.features[i].properties.list_sigla = data[j].list_sigla
                        distritos.features[i].properties.full_name = data[j].full_name
                        change = true
                        break;
                    }
                }
                if (!change) {
                    distritos.features[i].properties.list_sigla = ''
                    distritos.features[i].properties.full_name = ''
                }
            }
            setDistrito(distritos)
        }
    }

    const ChangeElections = (data) => {
        var _ = require('lodash');
        if (data) {
            const distritos = _.cloneDeep(districts)
            let change = false
            for (var i = 0; i < distritos.features.length; i++) {
                for (var j = 0; j < data.length; j++) {
                    if (data[j].district.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\W/g, '') === distritos.features[i].properties.dist_desc_.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\W/g, '')) {
                        distritos.features[i].properties.list_sigla = data[j].list_sigla
                        distritos.features[i].properties.full_name = data[j].full_name
                        change = true
                        break;
                    }
                }
                if (!change) {
                    distritos.features[i].properties.list_sigla = ''
                    distritos.features[i].properties.full_name = ''
                }
            }
            setDistrito(distritos)
        }
    }

    const onHover = useCallback(event => {
        const {
            features,
            srcEvent: { offsetX, offsetY }
        } = event;
        const hoveredFeature = features && features[0];

        setHoverInfo(
            hoveredFeature
                ? {
                    feature: hoveredFeature,
                    x: offsetX,
                    y: offsetY
                }
                : null
        );
    }, []);

    const resetPosition = () => {
        setViewport({
            ...viewport,
            latitude: -23.4380203,
            longitude: -58.4483065,
            width: 400,
            height: 400,
            zoom: 6,
        }
        )
    }

    const onYearChange = (value) => {
        setYear(value)
    }

    if (!distrito) {
        return (
            <div>Cargando...
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                    timeout={10000}
                />
            </div>
        )
    }
    return (
        <>
            <ReactMapGL
                {...viewport}
                onViewportChange={nextViewport => setViewport(nextViewport)}
                width="100vw"
                height="100vh"
                mapStyle="mapbox://styles/mapbox/dark-v9"
                interactiveLayerIds={['data']}
                onHover={onHover}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                countries="PY"
            >
                <Source type="geojson" data={distrito}>
                    <Layer id="layer" {...dataLayer} />
                    <Layer id="border" type="line" layerProperty={{
                        id: "cities_border_map_app",
                        type: "line",
                        "line-color": "#656964",
                        "line-width": 2,
                    }} />
                </Source>
                {hoverInfo && (
                    <div className="tooltip" style={{ left: hoverInfo.x, top: hoverInfo.y }}>
                        <div>Departamento: {hoverInfo.feature.properties.dpto_desc}</div>
                        <div>Distrito: {hoverInfo.feature.properties.dist_desc_}</div>
                        <div>Partido: {hoverInfo.feature.properties.list_sigla}</div>
                        <div>Candidato Electo: {hoverInfo.feature.properties.full_name}</div>
                    </div>
                )}
                <NavigationControl className="nav" showCompass={false} />
                <button className="reset" onClick={resetPosition}>
                    <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.1069e-05 9.98304C-0.00329636 10.1857 0.0550875 10.3847 0.167421 10.5534C0.279755 10.7222 0.440733 10.8529 0.629011 10.928L10.23 14.769L14.071 24.371C14.1452 24.5568 14.2734 24.716 14.439 24.8281C14.6046 24.9402 14.8 25.0001 15 25H15.016C15.2185 24.9968 15.4153 24.9322 15.5803 24.8147C15.7453 24.6972 15.8708 24.5324 15.94 24.342L23.94 2.34204C24.0055 2.16306 24.0185 1.9691 23.9774 1.783C23.9364 1.59689 23.843 1.42639 23.7083 1.29158C23.5736 1.15677 23.4032 1.06326 23.2171 1.02206C23.031 0.98086 22.837 0.993691 22.658 1.05904L0.658011 9.05904C0.467577 9.12847 0.302708 9.25408 0.185214 9.41925C0.0677203 9.58442 0.00313826 9.78137 1.1069e-05 9.98404V9.98304Z" fill="black" />
                    </svg>
                </button>
            </ReactMapGL>

            <ControlPanel year={year} onChange={value => onYearChange(value)} />

        </>
    );
}

export default Map;