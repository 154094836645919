export const dataLayer = {
    id: 'data',
    type: 'fill',
    paint: {
        'fill-color': ['match', ['get', 'list_sigla'],
            'PLRA', '#81C1E2',
            'ANR', '#DC7069',
            '', 'gray',
            '#EEF0A7'],
        'fill-opacity': 1
    }
};