import * as React from 'react';
import { Slider } from '@mui/material';

const marks = [
    {
        value: 1996,
        label: '1996',
    },
    {
        value: 2001,
        label: '2001',
    },
    {
        value: 2006,
        label: '2006',
    },
    {
        value: 2010,
        label: '2010',
    },
    {
        value: 2015,
        label: '2015',
    },
    {
        value: 2021,
        label: '2021'
    }
];


function ControlPanel(props) {
    const { year } = props;

    return (
        <div className="control-panel">
            <h3>Elecciones Municipales</h3>
            <p>
                Mapa mostrando los resultados de las elecciones municipales del año <b>{year}</b>.
            </p>
            <p>
                Fuente de datos: <a href="www.datos.gov.py">www.datos.gov.py</a>
            </p>
            <hr />

            <div key={'year'} className="input">
                <label>Año: </label>
                <Slider
                    aria-labelledby="years-slider"
                    className="slider"
                    min={1996}
                    max={2021}
                    defaultValue={2021}
                    onChange={evt => props.onChange(evt.target.value.toString())}
                    step={null}
                    valueLabelDisplay="auto"
                    marks={marks}
                />
            </div>
        </div>
    );
}

export default ControlPanel;